import React from 'react';

export default class Notice extends React.Component {

  render() {
    return (
      <>
        <h2><strong>システム更新</strong></h2><br className="sp"/><h3>令和6年12月25日(水)</h3>
        <ul>
          <li>建築情報マップ（都市景観協定地区、団地認定）を令和6年12月3日現在に更新しました。</li>
          <li>次回更新は令和7年3月下旬頃を予定しています。</li>
        </ul>
        <br />
      </>
    );
  }

}