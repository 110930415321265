import React from 'react';

export default class UpdateDate extends React.Component {

  render() {
    return (
      <>
        令和6年12月3日
      </>
    );
  }

}